:root {
	--green: #a0d644;
	--green-hover: #89b63a;
	--red: #ea0d0d;
	--gray: #ebebeb;
	--blue: #229dc4;
	--dark-blue: #042949;
	--dark-gray: #383b49;
	--thankyou-emails-color: rgba(4, 41, 73, 0.5);
	--disable-btn-color: #ebebeb;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background: rgba(34, 157, 196, 0.7);
	border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
	background: rgba(34, 157, 196, 0.9);
}
::-webkit-scrollbar-track {
	background: #f0f0f000;
	border-radius: 0px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: 'Lato', sans-serif;
	/* transition: all ease-out 250ms; its broke a select  */
}



.App {
	text-align: center;
	background-image: url('./assets/imgs/main-background-light.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
	position: relative;
}

/* @mixin respond($breakpoint_number) {
	$breakpoint: $breakpoint_number + px;

	@media only screen and (max-width: $breakpoint) {
		@content;
	}
} */

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

* {
	margin: 0;
	padding: 0;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.card {
	background-color: #fff;
	/* position: absolute; */
	/* top: 50%; */
	/* left: 50%; */
	/* transform: translate(-50%, -50%); */
	border-radius: 20px;
	box-shadow: 5px 5px 20px 5px rgba(55, 99, 255, 0.2);
	/* min-width: 1130px;
	width: max-content; */
	max-width: min(95%, 1300px);
	/* max-height: 730px; */
	text-align: start;
	display: flex;
	padding-inline: min(84px, 7.12vw);
	padding-block-start: min(70px, 16vw);
	padding-block-end: min(50px, 7.8vw);
	margin-inline: auto;
	margin-block: 44px 51px;
	position: relative;
}

.card > div {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
}

.prevButton {
	position: absolute;
	top: 15px;
	left: 25px;
}

.form-container {
	height: 100%;
	min-height: 640px;
	flex-direction: column;
	row-gap: 40px;
	justify-content: flex-start;
}

.MuiSelect-select {
	padding: 13px 14px;
	text-align: start;
}

.lineSpace {
	position: relative;
	height: 2px;
	width: 15px;
	background-color: rgba(4, 41, 73, 0.5);
	top: 30%;
	transform: translateY(-30%);
}

.virticalLineSpace {
	height: 100%;
	background-color: rgba(56, 59, 73, 0.2);
	width: 1px;
}

/* Apply the custom styling to the input[type="number"] element */
input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Others */
  }
  
  /* Hide the up and down arrows in webkit browsers */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

@font-face {
	font-family: 'Lato';
	src: url('./assets/fonts/Lato/Lato-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('./assets/fonts/Lato/Lato-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

.vi__character {
	border: unset;
	border-bottom: 2px solid #6eb8cf;
	background-color: transparent;
}

.vi__character.vi__character--inactive {
	border-bottom: 2px solid var(--gray);
}

.vi__character.vi__character--selected {
	outline: none;
	border-bottom: 2px solid var(--blue);
	background-color: #afecff43;
}

.flex {
	display: flex;
}

.column {
	flex-direction: column;
}

.justify-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.wrap {
	flex-wrap: wrap;
}

button {
	border: none;
	background: transparent;
	cursor: pointer;
}
